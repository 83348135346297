import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import React, { useRef, useState } from 'react';
import { createGenericContext } from '@playful/utils';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver.jsx';
var _createGenericContext = createGenericContext({
    displayName: 'VirtualList'
  }),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  useHook = _createGenericContext2[0],
  VirtualProvider = _createGenericContext2[1];
export var useVirtualList = useHook;
export function VirtualList(_ref) {
  var observerOptions = _ref.observerOptions,
    onIntersectionChange = _ref.onIntersectionChange,
    children = _ref.children;
  var _useState = useState(new Set()),
    visibleKeys = _useState[0],
    setVisibleKeys = _useState[1];
  var hasRendered = useRef(new Set());
  var observer = useIntersectionObserver(function (entries, observer) {
    var vi = new Set(visibleKeys);
    var _iterator = _createForOfIteratorHelper(entries),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var entry = _step.value;
        var key = entry.target.dataset.key;
        if (!key) continue;
        if (entry.isIntersecting) {
          vi.add(key);
          hasRendered.current.add(key);
        } else {
          vi.delete(key);
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    onIntersectionChange === null || onIntersectionChange === void 0 || onIntersectionChange(vi, entries, observer);

    // don't re-render if sets are identical
    setVisibleKeys(function (prev) {
      return vi.difference(prev).size ? vi : prev;
    });
  }, observerOptions);
  return __jsx(VirtualProvider, {
    value: {
      observer: observer,
      visibleKeys: visibleKeys,
      renderedKeys: hasRendered.current
    }
  }, children);
}